import React from 'react';
import './assets/Amenities.scss';

export const AmenitiesSection: React.FC = () => {

    return (
        <section className="section-amenities">

            <div className="container">
                <h2 className="heading"><span className="emphasized">Amenities</span></h2>
                <ul>
                    <li>Unique 1, 2 & 4 bedroom apartment homes</li>
                    <li>Walkable, vibrant neighborhood</li>
                    <li>Original style & architecture</li>
                    <li>Modern eclectic vibe</li>
                    <li>Recycling on-site</li>
                    <li>Convenient bus access to IU</li>
                    <li>Toto’s Uncle Cafe on-site</li>
                    <li>Swimming pool</li>
                    <li>Walking distance to grocery, shopping & dining</li>
                    <li>Community garden</li>
                    <li>Washer & dryer included</li>
                    <li>Pets welcome</li>
                    <li>Free, off-street parking</li>
                    <li>24 hour emergency maintenance</li>
                    <li>Managed by Renaissance Rentals</li>
                </ul>
            </div>
        </section>
    );
};
